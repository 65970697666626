import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const RelatedPostItem = ({
	title,
	link,
	date,
	featuredImage,
	altText,
}) => {
	return (
		<div>
			<div
				className={`apl-blog-relatedpost-item`}
			>
				<div className='apl-blog-relatedpost-item-content'>
					<Link to={`/blog/${link}/`}>
						<h5>{title}</h5>
					</Link>
					<p>{date}</p>
				</div>
				<div className='apl-blog-relatedpost-item-wrapper'>
					<GatsbyImage image={featuredImage} alt={altText} />
				</div>
			</div>
		</div>
	)
}

RelatedPostItem.displayName = 'RelatedPostItem'

RelatedPostItem.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	date: PropTypes.string,
	featuredImage: PropTypes.object,
	altText: PropTypes.string,
}

RelatedPostItem.defaultProps = {
	title: null,
	link: null,
	date: null,
	featuredImage: null,
	altText: null,
}

export default RelatedPostItem
