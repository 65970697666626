import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const SidebarBanner = ({ link, image, altText }) => {
	return (
		<div className='apl-blog-sidebarbanner is-hidden-touch'>
			<a href={link} className='banner banner-side'>
				<GatsbyImage image={image} alt={altText} />
			</a>
		</div>
	)
}

SidebarBanner.displayName = 'SidebarBanner'

SidebarBanner.propTypes = {
	link: PropTypes.string,
	image: PropTypes.object,
	altText: PropTypes.string,
}

SidebarBanner.defaultProps = {
	link: null,
	image: null,
	altText: null,
}

export default SidebarBanner
