import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import ContentShare from './ContentShare'
import useInsertBanner from '../../../hooks/useInsertBanner'

const Content = ({
	title,
	author,
	date,
	categories,
	featuredImage,
	altText,
	content,
	shares,
	banner1,
	banner2,
	banner3,
	url1,
	url2,
	url3,
}) => {
	const renderContent = useInsertBanner(
		content,
		banner1,
		banner2,
		banner3,
		url1,
		url2,
		url3
	)

	return (
		<div className='apl-blog-content-detail'>
			<div className='apl-blog-content-detail-tags'>
				{categories &&
					categories.map((item, index) => (
						<span
							key={index}
							className='apl-blog-content-detail-tags-list'
						>
							<Link to={`/blog/${item.slug}/`}>{item.name}</Link>
						</span>
					))}
			</div>
			<h1>{title}</h1>
			<div className='apl-blog-content-detail-meta'>
				<div className='apl-blog-content-detail-meta-author'>
					<span>
						<span className='apl-blog-content-detail-meta-author-title'>
							Oleh {author}
						</span>
						<span className='apl-blog-content-detail-meta-author-separator'>
							/
						</span>
					</span>
					<span>
						<span className='apl-blog-content-detail-meta-author-title'>
							{date}
						</span>
						<span className='apl-blog-content-detail-meta-author-separator'>
							/
						</span>
					</span>
				</div>
				<div className='apl-blog-content-detail-meta-share'>
					<span>
						<span className='apl-blog-content-detail-meta-share-title'>
							Share
						</span>
						<span className='apl-blog-content-detail-meta-share-separator'>
							:
						</span>
					</span>
					<ContentShare
						url={shares.url}
						title={shares.title}
						via={shares.via}
						hashtags={shares.hashtags}
					/>
				</div>
			</div>
			<div className='apl-blog-content-detail-body'>
				<GatsbyImage image={featuredImage} alt={altText} />
				<div className='content'>{renderContent}</div>
			</div>
			<div className='apl-blog-content-detail-meta'>
				<div className='apl-blog-content-detail-meta-share'>
					<span>
						<span className='apl-blog-content-detail-meta-share-title'>
							Share
						</span>
						<span className='apl-blog-content-detail-meta-share-separator'>
							:
						</span>
					</span>
					<ContentShare
						url={shares.url}
						title={shares.title}
						via={shares.via}
						hashtags={shares.hashtags}
					/>
				</div>
			</div>
		</div>
	)
}

Content.displayName = 'Content'

Content.propTypes = {
	title: PropTypes.string,
	author: PropTypes.string,
	date: PropTypes.string,
	categories: PropTypes.array,
	featuredImage: PropTypes.object,
	altText: PropTypes.string,
	content: PropTypes.any,
	banner1: PropTypes.any,
	banner2: PropTypes.any,
	banner3: PropTypes.any,
	url1: PropTypes.string,
	url2: PropTypes.string,
	url3: PropTypes.string,
}

Content.defaultProps = {
	title: null,
	author: null,
	date: null,
	categories: null,
	featuredImage: null,
	altText: null,
	content: null,
	banner1: null,
	banner2: null,
	banner3: null,
	url1: null,
	url2: null,
	url3: null,
}

export default Content
