import React from 'react'
import PropTypes from 'prop-types'

const RelatedPost = ({ title, children }) => {
	return (
		<div className='apl-blog-relatedpost is-hidden-touch'>
			<h4>{title}</h4>
			<div className='columns is-multiline'>{children}</div>
		</div>
	)
}

RelatedPost.displayName = 'RelatedPost'

RelatedPost.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
}

RelatedPost.defaultProps = {
	title: null,
	children: null,
}

export default RelatedPost