import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

const useInsertBanner = (content, image1, image2, image3, url1, url2, url3) => {
	const result = parse(content)

	const banner1 = (
		<a className='banner banner-top' href={url1}>
			<span>
				<GatsbyImage image={image1} alt='wpg-banner' />
			</span>
		</a>
	)
	const banner2 = (
		<a className='banner banner-mid' href={url2}>
			<span>
				<GatsbyImage image={image2} alt='wpg-banner' />
			</span>
		</a>
	)
	const banner3 = (
		<a className='banner banner-bottom' href={url3}>
			<span>
				<GatsbyImage image={image3} alt='wpg-banner' />
			</span>
		</a>
	)

	if (result.length >= 4) {
		result.splice(4, 0, banner1)
	}

	if (result.length >= 8) {
		result.splice(result.length, 0, banner3)
	}

	if (result.length >= 12) {
		result.splice(result.length / 2, 0, banner2)
	}

	return result
}

export default useInsertBanner
